import DefaultLayout from "~ui/DefaultLayout/DefaultLayout";
import { Error404 } from "~features/static-pages/404";

export default function ErrorPage() {
  return (
    <DefaultLayout>
      <Error404 />
    </DefaultLayout>
  );
}
