import styles from "./404.module.css";
export function Error404() {
  return (
    <>
      <div className={styles.errorPage}>
        <div className={styles.errorBody}>
          <h2 className={styles.errorTitle}>Page Not Found</h2>
          <figure>
            <img
              className={styles.errorImage}
              src="https://static.scientificamerican.com/sciam/assets/404.jpg"
              alt=""
            />
            <figcaption className={styles.imageCaption}>
              Credit: Getty Images/Scientific American
            </figcaption>
          </figure>
          <p className={styles.subheader}>
            We’re sorry. The page you were looking for could not be found.
          </p>
          <p className={styles.errorMessage}>
            Please visit our{" "}
            <a
              href="https://www.scientificamerican.com/page/frequently-asked-questions/subscriptions-issues/"
              className={styles.links}
            >
              FAQ pages
            </a>{" "}
            for more information on subscriptions, institutional access and more, or browse through
            our{" "}
            <a
              href="https://www.scientificamerican.com/archive/issues/2020s/"
              className={styles.links}
            >
              archive
            </a>{" "}
            for a magazine article from a specific issue.
          </p>
        </div>
      </div>
    </>
  );
}
